.content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 80%;

  @media screen and (max-width: 700px) {
    height: 100%;
    justify-content: flex-start;
  }
}

.toastContainer {
  margin-top: 100px;
  margin-right: 35px;
  @media (max-width: 768px) {
    margin-top: 0;
  }
}
