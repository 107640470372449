@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;700&display=swap');

p {
  margin: 0;
  font-family: 'Nunito';
}

html,
body,
#root {
  height: 100%;
  background-color: #f8f8f8;
}
