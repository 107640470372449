@import 'variables.scss';

.card {
  width: 100%;
  max-width: 650px;
  min-width: 250px;
  height: fit-content;
  border-radius: 1.25rem;
  padding: 2rem 2rem;
  background: #ffffff;
  box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff, -20px 20px 60px #d9d9d9,
    20px -20px 60px #ffffff;
  animation-name: intoview;
  animation-duration: 0.4s;
  font-family: Nunito;
  transition: all 0.3s ease-in-out;
  box-sizing: border-box;

  @media screen and (max-width: 700px) {
    box-shadow: none;
    max-width: 580px;
    padding: 0 20px;
    background-color: #f8f8f8;
    margin-top: 30px;
  }

  .heading {
    font-size: 1.5rem;
  }

  .poolName{
    color: $primaryColor;
    font-weight: bolder;
  }

  .center {
    padding: 2rem 0 0.5rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    animation-name: intoview;
    animation-duration: 0.1s;

    .withdrawButton {
      width: 100%;
      font-family: Nunito;
      border: none;
      padding: 0.25rem 0.5rem;
      cursor: pointer;
      user-select: none;
      background-color: $primaryColor;
      color: #fff;
      transition: 0.2s;
      border-radius: 0.5rem;
      font-size: 1.1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.8rem 1rem;
      transition: 0.2s;
      &:hover {
        transform: scale(1.01);
        border-radius: 0.5rem;
      }
    }

    .input {
      display: flex;
      width: 100%;
      @media screen and (max-width: 425px) {
        flex-direction: column;
      }
      input {
        width: 80%;
        padding: 0.8rem 1rem;
        border-radius: 7px;
        border: 1px solid #bdbdbd;
        font-family: Nunito;
        font-size: 1.1rem;
        @media screen and (max-width: 425px) {
          width: 90%;
        }
      }
      button {
        width: 20%;
        font-family: Nunito;
        border: none;
        padding: 0.25rem 0.5rem;
        cursor: pointer;
        user-select: none;
        background-color: $primaryColor;
        color: #fff;
        transition: 0.2s;
        border-radius: 0.5rem;
        margin-left: 1rem;
        font-size: 1.1rem;
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 425px) {
          width: 100%;
          margin-left: 0;
          margin-top: 1rem;
          padding: 0.8rem 0;
        }

        &:hover {
          transform: scale(1.05);
          border-radius: 0.5rem;
        }

        &:disabled {
          transform: scale(1);
          cursor: not-allowed;
        }
      }
    }
  }
  .stats {
    display: flex;
    justify-content: space-between;
    width: 100%;
    @media screen and (max-width: 575px) {
      flex-direction: column;
    }
    .stat {
      width: 47%;
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 575px) {
        width: 100%;
        margin-bottom: 10px;
        font-size: 15px;
      }
      @media screen and (max-width: 350px) {
        font-size: 12px;
      }
      @media screen and (max-width: 300px) {
        font-size: 11px;
      }
    }
  }

  .note {
    padding-top: 2rem;
    font-size: 15px;
    @media screen and (max-width: 525px) {
      padding-top: 3rem;
      padding-bottom: 3rem;
    }
  }

  .message {
    padding-top: 2rem;
    font-size: 20px;
    @media screen and (max-width: 525px) {
      padding-top: 3rem;
      padding-bottom: 3rem;
    }
  }
}

@keyframes intoview {
  from {
    transform: translateY(40px);
  }
  to {
    transform: translateY(0px);
  }
}
