@import 'variables.scss';

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 3rem;
  height: fit-content;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }

  img {
    max-height: 4.5rem;
    margin-bottom: 1rem;
  }

  .navItem {
    width: 33.33%;
  }

  .button {
    padding: 0.25rem 0.5rem;
    cursor: pointer;
    user-select: none;
    background-color: $primaryColor;
    color: white;
    transition: 0.2s;
    border-radius: 0.5rem;
    border: none;
    padding: 0.5rem 1rem;
    font-family: Nunito;
    text-align: center;
    @media screen and (max-width: 1000px) {
      margin-top: 0.5rem;
      background-color: #fff;
      border: 1px solid $primaryColor;
      color: $primaryColor;
      padding: 0.25rem 0.5rem;
    }
    &:hover {
      transform: scale(1.05);
      transition: 0.2s;
      border-radius: 0.5rem;
    }
  }

  .connected {
    display: flex;

    .walletAddress {
      margin-right: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0.5rem;
      color: white;
      border: 2px solid $primaryColor;
      color: $primaryColor;
      padding: 5px 10px;

      @media screen and (max-width: 1000px) {
        flex-direction: column;
        margin-right: 0;
        color: $primaryColor;
        background-color: #f8f8f8;
      }

      @media screen and (max-width: 425px) {
        font-size: 12px;
      }
    }

    @media screen and (max-width: 1000px) {
      flex-direction: column;
      align-items: center;
    }
  }
}
